import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contacts from '../sections/contacts';


const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
        <Contacts />
    </Layout>
  );
}

export default ContactPage
